<template>
  <div v-fragment>
    <portal to="doc-sidebar-header">
      <TaskSectionMenu v-if="!isClientPortal" @select="onTaskSectionMenuSelect" />
    </portal>
    <div class="relative flex flex-col">
      <!-- <div
        v-if="!document.sharingClientEnabled"
        class="p-8 mt-12 leading-none bg-blue-100 rounded-md text-13 text-black/70"
      >
        <span class="icon_v2-so_lock text-12" />
        <strong class="ml-4">Private document.</strong><br />
        Action items will not be visible to the client.
      </div> -->
      <div class="p-8">
        <ProgressBarCompleted
          :count="taskCount"
          :completed-count="resolvedTaskCount"
        />
      </div>
      <SlickList
        v-if="actionItems.length"
        v-model="actionItems"
        use-drag-handle
        lock-to-container-edges
        lock-axis="y"
        helper-class="bg-white z-1000"
      >
        <SlickItem
          v-for="(item, index) in actionItems"
          :key="item._id"
          :index="index"
        >
          <ActionItemOpen
            v-if="item._id !== activeItemId"
            :item="item"
            :is-viewer="isViewer"
            :disable-drag="disableDrag"
            :size="size"
            :class-name="className"
            :dense="dense"
            :hide-menu="isClientPortal && item.createdBy !== user._id"
            @checkbox-change="isResolved => updateTask(item._id, { isResolved })"
            @delete-item="remove(item)"
            @edit-item="edit(item)"
          />
          <ActionItemEditor
            v-else
            :project="project"
            :action-item="item"
            :size="size"
            @save="closeEditor"
            @cancel="closeEditor"
          />
        </SlickItem>
      </SlickList>

      <p
        v-if="!actionItems.length"
        class="mb-8 text-gray-600 dark:text-darkGray-400 text-14 px-8 mt-16"
      >
        No action items yet
      </p>

      <!-- New Action Item -->
      <ActionItemEditor
        v-if="itemEditorActive && !activeItemId"
        :project="project"
        :document-id="document._id "
        :size="size"
        @cancel="closeEditor"
        @save="closeEditor"
      />

      <div
        :class="itemClass.buttonSpacing"
        class="p-8"
      >
        <FeatureLimit
          v-slot="{ checkFeatureLimit }"
          flag="limitActionItems"
          label="action items"
          @allow="toggleItemEditor"
        >
          <b-button
            v-if="!itemEditorActive && !activeItemId"
            variant="neutral"
            :size="itemClass.buttonSize"
            :class="{ 'block w-full p-16': dense }"
            @click="checkFeatureLimit"
          >
            {{ $t('action-items.add-action-item', { name: actionItemName }) }}
          </b-button>
        </FeatureLimit>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, inject } from '@vue/composition-api'
import pluralize from 'pluralize'
import { SlickList, SlickItem } from 'vue-slicksort'
import useLocalization from '@/v2/lib/composition/useLocalization'
import { useDocumentsTasks, useProjectModule } from '@/v2/services/projects/compositions'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import ActionItemOpen from '@/components/ActionItemOpen.vue'
import ActionItemEditor from '@/components/ActionItemEditor.vue'
import FeatureLimit from '@/components/FeatureLimit.vue'
import ProgressBarCompleted from '@/components/ProgressBarCompleted.vue'
import TaskSectionMenu from '@/components/Tasks/TaskSectionMenu.vue'

const classMap = {
  S: {
    buttonSize: 'xs',
    buttonSpacing: 'pb-16',
  },
  L: {
    itemFontSize: 'sm',
    buttonSpacing: 'mt-12 pb-16',
  },
}

export default defineComponent({
  name: 'ActionItems',
  components: {
    ActionItemOpen,
    ActionItemEditor,
    SlickList,
    SlickItem,
    FeatureLimit,
    ProgressBarCompleted,
    TaskSectionMenu,
  },
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    project: {
      type: Object,
      default: null,
    },
    organizationId: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: 'flex',
    },
    isViewer: {
      type: Boolean,
      default: false,
    },
    displayPrivate: {
      type: Boolean,
      default: true,
    },
    disableDrag: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'S',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isClientPortal = inject('isClientPortal', false)
    const { track } = useTracking()
    const { user } = useUser()

    const project = computed(() => props.project)

    const options = computed(() => ({
      documentsQuery: {
        _id: props.document._id,
      },
    }))

    const {
      sections,
      handleSectionMenuAction,

      // tasks
      reorderTasks,
      updateTask,
      removeTask,
    } = useDocumentsTasks(project, options)

    const section = computed(() => sections.value?.[0])

    const resolvedTaskCount = computed(
      () => section.value?.tasks.filter(task => task.isResolved).length ?? 0
    );

    const taskCount = computed(() => section.value?.tasks.length ?? 0)

    const actionItems = computed({
      get() {
        return section.value?.tasks ?? []
      },
      set(reorderedTasks) {
        reorderTasks(section.value, reorderedTasks)
      },
    })

    const remove = actionItem => {
      removeTask(section.value, actionItem)
    }

    const onTaskSectionMenuSelect = action => {
      handleSectionMenuAction(section.value, action)
    }

    const itemEditorActive = ref(false)
    const activeItemId = ref(false)
    const autofocusItemEditor = ref(true)
    const itemClass = computed(() => classMap[props.size])
    const { translate } = useLocalization()
    const tasksModule = useProjectModule(computed(() => props.project), 'tasks')

    const actionItemName = computed(() => (tasksModule.value.title
      ? pluralize(tasksModule.value.title.toLowerCase(), 1)
      : translate('modules.action-items').value.toLowerCase()))

    const edit = item => {
      activeItemId.value = item._id
    }

    const closeEditor = () => {
      activeItemId.value = null
      itemEditorActive.value = null
    }

    const toggleItemEditor = () => {
      itemEditorActive.value = !itemEditorActive.value
      if (itemEditorActive.value) {
        track('New Action Item Form Opened')
      }
    }

    const setIsResolved = (item, isResolved) => {
      item.patch({ data: { isResolved } })
    }

    return {
      activeItemId,
      autofocusItemEditor,
      itemClass,
      actionItemName,

      taskCount,
      resolvedTaskCount,

      actionItems,

      // flags
      itemEditorActive,
      onTaskSectionMenuSelect,

      // methods
      edit,
      remove,
      updateTask,
      closeEditor,
      setIsResolved,
      toggleItemEditor,

      isClientPortal,
      user,
    }
  },
})
</script>
