
<div v-fragment>
  <portal to="doc-sidebar-header">
    <TaskSectionMenu v-if="!isClientPortal" @select="onTaskSectionMenuSelect" />
  </portal>
  <div class="relative flex flex-col">
    <!-- <div
      v-if="!document.sharingClientEnabled"
      class="p-8 mt-12 leading-none bg-blue-100 rounded-md text-13 text-black/70"
    >
      <span class="icon_v2-so_lock text-12" />
      <strong class="ml-4">Private document.</strong><br />
      Action items will not be visible to the client.
    </div> -->
    <div class="p-8">
      <ProgressBarCompleted
        :count="taskCount"
        :completed-count="resolvedTaskCount"
      />
    </div>
    <SlickList
      v-if="actionItems.length"
      v-model="actionItems"
      use-drag-handle
      lock-to-container-edges
      lock-axis="y"
      helper-class="bg-white z-1000"
    >
      <SlickItem
        v-for="(item, index) in actionItems"
        :key="item._id"
        :index="index"
      >
        <ActionItemOpen
          v-if="item._id !== activeItemId"
          :item="item"
          :is-viewer="isViewer"
          :disable-drag="disableDrag"
          :size="size"
          :class-name="className"
          :dense="dense"
          :hide-menu="isClientPortal && item.createdBy !== user._id"
          @checkbox-change="isResolved => updateTask(item._id, { isResolved })"
          @delete-item="remove(item)"
          @edit-item="edit(item)"
        />
        <ActionItemEditor
          v-else
          :project="project"
          :action-item="item"
          :size="size"
          @save="closeEditor"
          @cancel="closeEditor"
        />
      </SlickItem>
    </SlickList>

    <p
      v-if="!actionItems.length"
      class="mb-8 text-gray-600 dark:text-darkGray-400 text-14 px-8 mt-16"
    >
      No action items yet
    </p>

    <!-- New Action Item -->
    <ActionItemEditor
      v-if="itemEditorActive && !activeItemId"
      :project="project"
      :document-id="document._id "
      :size="size"
      @cancel="closeEditor"
      @save="closeEditor"
    />

    <div
      :class="itemClass.buttonSpacing"
      class="p-8"
    >
      <FeatureLimit
        v-slot="{ checkFeatureLimit }"
        flag="limitActionItems"
        label="action items"
        @allow="toggleItemEditor"
      >
        <b-button
          v-if="!itemEditorActive && !activeItemId"
          variant="neutral"
          :size="itemClass.buttonSize"
          :class="{ 'block w-full p-16': dense }"
          @click="checkFeatureLimit"
        >
          {{ $t('action-items.add-action-item', { name: actionItemName }) }}
        </b-button>
      </FeatureLimit>
    </div>
  </div>
</div>
