<template>
  <nav>
    <ProjectTheme :project="project">
      <SidebarGroup>
        <!-- dashboard -->
        <SidebarMenu>
          <SidebarMenuLink :to="{ name: routes.projectDashboard }">
            <SidebarItemBasic icon="icon_v2-so_home" label="Dashboard" />
          </SidebarMenuLink>

          <!-- tasks -->
          <SidebarMenuLink
            v-if="projectModules.tasks.enabled"
            :to="{ name: routes.projectTasks }"
            activate-on-child-route
          >
            <SidebarItemBasic
              :icon="projectModules.tasks.icon || 'icon_v2-so_action-items'"
              :label="projectModules.tasks.title || $t('modules.tasks')"
            >
              <!-- <template #right>
                <ProjectNavModuleVisibility :enabled="projectModules.tasks.enabledPortal" />
              </template> -->
            </SidebarItemBasic>
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup
        v-if="groupEnabled.files"
        label="Files"
      >
        <SidebarMenu>
          <!-- docs & files -->
          <template v-if="projectModules.documents.enabled">
            <mq-layout :mq="['sm', 'md', 'lg']">
              <SidebarMenuLink
                :to="{
                  name: routes.projectFsFolder,
                  params: {
                    folderId: project.rootFolder,
                    projectId: project._id,
                  },
                }"
                activate-on-child-route
                :activate-on-parent-routes="[routes.projectFsFolder]"
              >
                <SidebarItemBasic
                  :icon="projectModules.documents.icon || 'icon_v2-so_folder'"
                  :label="projectModules.documents.title || $t('modules.docs-and-files')"
                >
                  <!-- <template #right>
                    <ProjectNavModuleVisibility :enabled="projectModules.documents.enabledPortal" />
                  </template> -->
                </SidebarItemBasic>
              </SidebarMenuLink>
            </mq-layout>
            <mq-layout mq="xl+">
              <SidebarTree
                :node="folders"
                :hide-empty-nodes="false"
              >
                <template #folder="{ node, isExpanded }">
                  <SidebarTreeItemFolder :node="node" :is-open="isExpanded" />
                </template>
              </SidebarTree>
            </mq-layout>
          </template>

          <template v-if="projectModules.pinnedFolders.enabled">
            <SidebarItemFolder
              v-for="pinnedFolder in pinnedFolders"
              :key="`pinned-folder-${pinnedFolder._id}`"
              :document="pinnedFolder"
              pinned
            />
          </template>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup v-if="groupEnabled.apps" label="Apps">
        <!-- <template #right>
          <ProjectNavModuleVisibility :enabled="projectModules.embeddedApps.enabledPortal" />
        </template> -->
        <SidebarMenu>
          <!-- pinned apps -->
          <SidebarMenuLink
            v-for="app in project.portalLinkedApps"
            :key="app._id"
            :to="{ name: routes.projectLinkedApp, params: { linkedAppId: app._id } }"
          >
            <SidebarItemBasic
              :label="app.title"
              :icon="app.icon || 'icon_v2-so_apps'"
            />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup v-if="groupEnabled.packagedServices" label="Services">
        <!-- <template #right>
          <ProjectNavModuleVisibility :enabled="projectModules.packagedServices.enabledPortal" />
        </template> -->
        <SidebarMenu>
          <SidebarMenuLink
            v-for="service in services"
            :key="`service-${service._id}`"
            :to="{
              name: routes.projectService,
              params: {
                organization,
                project,
                documentId: service._id
              }
            }"
          >
            <SidebarItemBasic
              :icon="projectModules.packagedServices.icon || 'icon_v2-so_open-box'"
              :label="service.title"
            />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup v-if="groupEnabled.pages" :label="projectModules.pages.title || 'Pages'">
        <!-- <template #right>
          <ProjectNavModuleVisibility :enabled="projectModules.pages.enabledPortal" />
        </template> -->
        <SidebarMenu>
          <SidebarMenuLink
            v-for="page in pages"
            :key="`page-${page._id}`"
            :to="{
              name: routes.projectPage,
              params: {
                organization,
                project,
                documentId: page._id
              }
            }"
          >
            <SidebarItemBasic
              icon="icon_v2-so_direction-right"
              :label="page.title"
            />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup label="Activity">
        <!-- <template #right>
          <ProjectNavModuleVisibility :enabled="false" />
        </template> -->
        <SidebarMenu>
          <SidebarMenuLink
            v-if="routes.projectNotifications"
            :to="{ name: routes.projectNotifications }"
          >
            <SidebarItemBasic icon="icon_v2-so_notification-ringing" label="Notifications" />
          </SidebarMenuLink>
          <!-- timeline -->
          <SidebarMenuLink
            v-if="projectModules.timeline.enabled"
            :to="{ name: routes.projectUpdates }"
          >
            <SidebarItemBasic
              :label="projectModules.timeline.title || 'Timeline'"
              :icon="projectModules.timeline.icon || 'icon_v2-so_timeline'"
            />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>
    </ProjectTheme>
  </nav>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useFindByIds from '@/v2/lib/composition/useFindByIds'
import { useProjectModules } from '@/v2/services/projects/compositions'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'
import SidebarItemFolder from '@/components/Sidebar/SidebarItemFolder.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import SidebarTree from '@/components/Sidebar/SidebarTree.vue'
import SidebarTreeItemFolder from '@/components/Sidebar/SidebarTreeItemFolder.vue'
// import ProjectNavModuleVisibility from '@/components/ProjectNavModuleVisibility.vue'

export default defineComponent({
  name: 'ProjectNav',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
    SidebarItemFolder,
    ProjectTheme,
    SidebarTreeItemFolder,
    SidebarTree,
    // ProjectNavModuleVisibility,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    folders: {
      type: Object,
      default: () => ({}),
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // pinned folders
    const pinnedFoldersIds = computed(() => props.project.pinnedFolders)
    const { items: pinnedFolders } = useFindByIds('Document', pinnedFoldersIds)

    // pages
    const pageIds = computed(() => props.project.pages)
    const { items: pages } = useFindByIds('Document', pageIds)

    // packaged services
    const serviceIds = computed(() => props.project.packagedServices)
    const { items: services } = useFindByIds('Document', serviceIds)

    const projectModules = useProjectModules(computed(() => props.project))

    const groupEnabled = computed(() => ({
      files: projectModules.value.documents.enabled
         || (projectModules.value.pinnedFolders.enabled && props.project.pinnedFolders?.length),
      apps: projectModules.value.embeddedApps.enabled
        && props.project.portalLinkedApps?.length,
      packagedServices: projectModules.value.packagedServices.enabled
        && props.project.packagedServices?.length,
      pages: projectModules.value.pages.enabled
        && props.project.pages?.length,
    }))

    return {
      pinnedFolders,
      pages,
      services,
      projectModules,
      groupEnabled,

      // PROJECT_TASKS,
      // PROJECT_SETTINGS,
      // PROJECT_DASHBOARD,
      // PROJECT_KNOWLEDGE_BASE,
      // PROJECT_LINKED_APP,
      // PROJECT_UPDATES,
      // PROJECT_NOTIFICATIONS,
      // PROJECT_VIEW_AS_CLIENT,
      // PROJECT_PAGE,
      // PROJECT_SERVICE,
      // PROJECT_FS_FOLDER,
    }
  },
})
</script>
